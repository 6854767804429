
.form-control {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-radius: 0;
    box-shadow: none;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d2d6de;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
    
    text-align: center;
    font-weight: lighter;
    padding: 5px;
    margin-bottom:10px;
  }
  .form-control-feedback {
    color: #777777;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
  }
  .has-feedback {
    position: relative;
  }