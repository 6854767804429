.questionText {
  font-size: 16px;
  font-weight: 500;
}
.groupText {
  font-size: 20px;
  font-weight: 500;
}
a {
  text-decoration: none;
  color: white;
}
label {
  width: 100%;
}
a:hover {
  text-decoration: none;
  color: white;
}
button {
  font-weight: 500;
  border-radius: 25px;
  color: #2e485d;
  background-color: whitesmoke;
  padding: 5px 20px;
  border: 1px solid transparent;
  cursor: pointer;
}
button:hover {
  background-color: #d1e0ec;
}
button.submitbtn {
  color: rgba(255, 255, 255, 0.83);
  background-color: #ff1526;
}
button.submitbtn:disabled {
  color: rgba(255, 255, 255, 0.83);
  background-color: #8a6769;
  cursor: not-allowed;
}
button.submitbtn:hover:not(:disabled) {
  color: white;
}
input[type="file"] {
  display: none;
}
input[type="text" i],
input[type="number"] {
  border-color: #2e485d;
  width: 100%;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 5px;
}
label {
  display: block;
}
.deleteFile {
  color: #1d242d;
  cursor: pointer;
}
.deleteFile:hover {
  color: #ff1526;
}

.group {
  background: rgba(46, 72, 93, 0.1);
  padding: 5px;
}
.formName {
  border: rgba(46, 72, 93, 0.1);
  border-style: solid;
  border-width: 1px 0 1px 0;
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  background: white;
}
