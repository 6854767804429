.App {
  background-color: whitesmoke;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

.App-header {
  text-align: center;
  background-color: #2e485d;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 6px #00000033;
  opacity: 1;
  align-items: center;
  justify-content: flex-end;
  padding-right: 100px;
}
.AppLogo {
  left: 100px;
  position: absolute;
}
.navbutton {
  font-size: 12px;
  margin-right: 5px;
  text-align: center;
  right: 0px;
  position: relative;
  cursor: pointer;
  height: fit-content;
}
.card {
  margin: 0 auto;
  background: rgba(255, 255, 255, 00.8);
  opacity: 1;
  backdrop-filter: blur(10px);
  width: 60vw;
  padding: 40px;
}
